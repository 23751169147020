import React, { memo, useCallback, useMemo } from "react"
import { navigate } from "gatsby"

import { useCore } from "../../../../hooks/useCore"
import { useCustomerAccount } from "../../../../hooks/useCustomer"
import { useRoutes } from "../../../../hooks/useRoutes"

export const withAccountResetForm = Component =>
  memo(({ name = "AccountResetForm", location, page }: any) => {
    const {
      helpers: { isBrowser },
    } = useCore()
    const { resetCustomer, data, setData, loading, errors, setErrors } = useCustomerAccount()
    const { routeResolver } = useRoutes()

    const { customer, token, valid } = useMemo(() => {
      const parts = location?.pathname?.split(`/`) || []
      const customer = parts?.[parts?.length - 2] || null
      const token = parts?.[parts?.length - 1] || null

      const valid = customer && token && parts?.length === 5

      if (!valid && isBrowser) {
        navigate(routeResolver({ type: "invalid" }), { replace: true })
      }

      return { customer, token, valid }
    }, [isBrowser, location, routeResolver])

    const handleSubmit = useCallback(
      async event => {
        event.preventDefault()

        if (data?.password !== data?.passwordConfirm) {
          setErrors([{ code: 0, message: page?.additionalPasswordMatch }])
        } else {
          await resetCustomer(customer, token, data?.password)
        }
      },
      [customer, data, page, resetCustomer, setErrors, token]
    )

    const handleChange = useCallback(
      ({ target: { type, name, value, checked } }) => {
        setData(prevState => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        }))
      },
      [setData]
    )

    Component.displayName = name
    return useMemo(
      () =>
        valid ? (
          <Component data={data} errors={errors} handleChange={handleChange} handleSubmit={handleSubmit} loading={loading} locales={page} />
        ) : null,
      [data, errors, handleChange, handleSubmit, loading, page, valid]
    )
  })
