import React from "react"

import { withAccountReset } from "./withAccountReset"
import { Breadcrumbs } from "../../Breadcrumbs/Breadcrumbs"
import { AccountResetForm } from "./Form/AccountResetForm"
import { StyledContainer } from "../../Styled/Container"
import { Page, Title } from "./styledAccountReset"

export const AccountReset = withAccountReset(
  ({ location, page }): JSX.Element => (
    <Page>
      <Breadcrumbs background={`orange-pastel`} colour={`dark`} page={page} />
      <StyledContainer width={`md`}>
        <Title as={`h1`} align={`center`} withSpacing>
          {page?.title}
        </Title>
        <AccountResetForm location={location} page={page} />
      </StyledContainer>
    </Page>
  )
)
