import React from "react"

import { withAccountResetForm } from "./withAccountResetForm"
import { FormInput } from "../../../Form/Input/FormInput"
import { Form, Button } from "./styledAccountResetForm"

export const AccountResetForm = withAccountResetForm(
  ({ data, errors, handleChange, handleSubmit, loading, locales }): JSX.Element => (
    <Form onSubmit={handleSubmit}>
      <FormInput
        autoFocus
        name={`password`}
        type={`password`}
        full
        onChange={handleChange}
        placeholder={locales?.additionalPassword}
        required
        value={data?.password}
        withSpacing={`xs`}
      />
      <FormInput
        name={`passwordConfirm`}
        type={`password`}
        errors={errors}
        full
        onChange={handleChange}
        placeholder={locales?.additionalPasswordConfirm}
        required
        value={data?.passwordConfirm}
        withSpacing={`xl`}
      />
      <Button type={`submit`} colour={`purple`} disabled={loading} size={`primary`} title={locales?.additionalSubmit}>
        {locales?.additionalSubmit}
      </Button>
    </Form>
  )
)
