import React from "react"
import { graphql } from "gatsby"

import { AccountReset as Page } from "../../components/Account/Reset/AccountReset"

export const query = graphql`
  query {
    page: sanityPageAccountReset {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      additionalPassword
      additionalPasswordConfirm
      additionalPasswordMatch
      additionalSubmit
    }
    template: sanityTemplateAccount {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => <Page {...props} {...data} />

export default Component
